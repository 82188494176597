<template>
  <div id="member-benefits">
    <b-container class="base-container-x member-benefit-header-box" style="min-height:500px;background-color:#fff">
      <div v-if="loading == true" class="col-12">
        <b-skeleton-img no-aspect height="200px"></b-skeleton-img>
      </div>
      <div id="banner" class="banner-inner member-benefit">
        <div v-if="loading == false" class="slideshow_caption">
          <div class="slideshow_inner_caption">
            <div class="slideshow_align_caption">
              <h1 class="section-header-title text-uppercase">{{ $t('Member Benefits') }}</h1>
              <h3 class="article-content">{{ $t('WYA membership allows you to receive several benefits from our organisation.') }}</h3>
            </div>
          </div>
        </div>
        <!-- <b-row v-if="loading == false"  class="inner-block-header">
          <b-col class="text-center slideshow_inner_caption" style="margin-bottom:100px">
            <div class="slideshow_align_caption">
              <h1 class="section-header-title text-uppercase text-white">{{ $t('Member Benefits') }}</h1>
              <h3 class="article-content text-white">{{ $t('WYA membership allows you to receive several benefits from our organisation.') }}</h3>
            </div>
          </b-col>
      </b-row> -->
      </div>
      <div v-if="loading == true" class="col-12 mt-4">
        <b-skeleton animation="fade" width="85%"></b-skeleton>
        <b-skeleton animation="fade" width="55%"></b-skeleton>
        <b-skeleton animation="fade" width="70%"></b-skeleton>
        <br/>
        <b-skeleton animation="fade" width="85%"></b-skeleton>
        <b-skeleton animation="fade" width="55%"></b-skeleton>
        <b-skeleton animation="fade" width="70%"></b-skeleton>
      </div>
      <div v-if="loading == false" class="col-12 mb-5 pt-4">
        <div id="content" class="mt-5">
          <h3 class="section-header-title text-primary-dark d-flex">{{ $t('WHY JOIN US AT WYA?') }}</h3>
          <p class="article-content mt-3">{{ $t('Benefit_world_yoga') }}</p>
          <ul>
            <li>{{ $t('We offer Lifetime License for both School & Teacher registration.') }}</li>
            <li>{{ $t('Affordable registration fee that requires only one time.') }}</li>
            <li>{{ $t('WYA affiliation and registration are recognised worldwide including U.S. and Europe.') }}</li>
            <li>{{ $t('FREE Public profile on our website.') }}</li>
            <li>{{ $t('FREE Promote for your yoga activities/trainings via WYA social media networks.') }}</li>
            <li>{{ $t('Get special discount for Member Park registration, yoga products, WYA family event, etc.') }}</li>
            <li>{{ $t('At WYA, where members are like a family, our active team members will provide you 24hrs assistance and support.') }}</li>
          </ul>
        </div>
        <div id="benefits" ref="scrollToMe" class="benefit-tabs box-benefit mt-5">
          <b-tabs content-class="mt-3" align="center" v-model="tabIndex">
            <b-tab title="SCHOOL BENEFITS">
              <b-table-simple caption-top responsive bordered class="table-default">
                <caption>{{ $t('For Schools') }}</caption>
                <b-thead>
                  <b-tr>
                    <b-th class="text-center" >{{ $t('Member Benefits') }}</b-th>
                    <b-th class="text-center" >{{ $t('Lifetime member (Premium)')}} </b-th>
                    <b-th class="text-center" >{{ $t('Annual member (Regular)')}} </b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr>
                    <b-td>{{ $t('Worldwide Accreditation') }}</b-td>
                    <b-td class="text-center">✔️</b-td>
                    <b-td class="text-center">✔️</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('Get listed on WYA International Yoga School Directory') }}</b-td>
                    <b-td class="text-center">✔️</b-td>
                    <b-td class="text-center">✔️</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('Permission to use WYA official logos/trademarks according to your registration') }}</b-td>
                    <b-td class="text-center">✔️</b-td>
                    <b-td class="text-center">✔️</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('Promote your training/events via website & social media') }}</b-td>
                    <b-td class="text-center">✔️</b-td>
                    <b-td class="text-center">✔️</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('Public school profile') }}</b-td>
                    <b-td class="text-center">✔️</b-td>
                    <b-td class="text-center">✔️</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('Add events on your public school profile') }}</b-td>
                    <b-td class="text-center">✔️</b-td>
                    <b-td class="text-center">✔️</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('Access to WYA online community') }}</b-td>
                    <b-td class="text-center">✔️</b-td>
                    <b-td class="text-center">✔️</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('Access to WYA exclusive educational center') }}</b-td>
                    <b-td class="text-center">✔️</b-td>
                    <b-td class="text-center">✔️</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('Join WYA continuous education program (EYEP)') }}</b-td>
                    <b-td class="text-center">✔️</b-td>
                    <b-td class="text-center">❌</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('Register your graduate as CYT (Certified Yoga Teachers)') }}</b-td>
                    <b-td class="text-center">✔️</b-td>
                    <b-td class="text-center">❌</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('Allow to use WYA course syllabus and training manual') }}</b-td>
                    <b-td class="text-center">✔️</b-td>
                    <b-td class="text-center">❌</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('Receive up to 40% discount for joining WYA member park where you can promote your yoga products') }}</b-td>
                    <b-td class="text-center">✔️</b-td>
                    <b-td class="text-center">❌</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('FREE participation in our Annual Global Conferences and WYA Family Event both Online and Onsite') }}</b-td>
                    <b-td class="text-center">✔️</b-td>
                    <b-td class="text-center">❌</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('An opportunity to work with WYA Community as our team member') }}</b-td>
                    <b-td class="text-center">✔️</b-td>
                    <b-td class="text-center">❌</b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
              <div class="row pt-3">
                <div class="col-12 col-md-2 mb-4">
                  <span class="d-block text-center">
                    <img :src="PUBLIC_PATH+'img/certificate/RSY.png'" width="90%" height="auto" title="RSY" alt="">
                  </span>
                   <span class="d-block mt-4 text-center">
                    <img :src="PUBLIC_PATH+'img/certificate/RSY_NEW_SAMPLE.jpg'" width="90%" height="auto" title="RSY" alt="" style="max-height=130px;">
                  </span>
                </div>
                 <div class="col-12 col-md-10">
                  <h5>{{$t('Benefits for RSY － Registered School of Yoga')}}</h5>
                  <ul>
                    <li>{{ $t('Accreditation from World Yoga Alliance (WYA) enhances your credibility nationally and internationally.') }}  </li>
                    <li>{{ $t('Get your school listed on WYA International Directory of Yoga School as official member.') }}</li>
                    <li>{{ $t('Permission to use WYA official logos according to your registration.') }}</li>
                    <li>{{ $t('15% discount for participation in the Annual Global Conference and WYA Family Event, where you can present the art of your teaching and promote your school globally.') }}</li>
                    <li>{{ $t('15% discount for educational support from WYA accredited schools on conferences, seminars, workshops, and events.') }}</li>
                    <li>{{ $t('Up to 40% discount for joining WYA member park where you can promote your yoga products.') }}</li>
                    <li>{{ $t('Promote and share your yoga activities/articles via WYA Social media.') }}</li>
                  </ul>
                </div>
              </div>
              <hr class="col-12 mt-5 mb-5" />
              <div class="row">
                <div class="col-12 col-md-2 mb-4">
                  <span class="d-block text-center">
                    <img :src="PUBLIC_PATH+'img/certificate/CYS.png'" width="100%" height="auto" title="CYS" alt="">
                  </span>
                   <span class="d-block mt-4 text-center">
                    <img :src="PUBLIC_PATH+'img/certificate/EYEP.png'" width="100%" height="auto" title="RSY" alt="">
                  </span>
                </div>
                <div id="CYS" class="col-12 col-md-10">
                  <h5>{{ $t('Benefits for CYS － Certified Yoga School') }}</h5>
                  <ul>
                    <li>{{ $t('Accreditation from World Yoga Alliance (WYA) enhances your credibility nationally and internationally') }}</li>
                    <li>{{ $t('Get your school listed on') }} <router-link :to="{ name: 'searchDirectory', query:{ type: 'school'}}">{{ $t('WYA International Directory of Yoga School') }}</router-link> {{ $t('as official member.') }}</li>
                    <li>{{ $t('Permission to use WYA official logos according to your registration.') }}</li>
                    <li>{{ $t('FREE participation in our Annual Global Conferences and WYA Family Event where you can present your art of teaching and promote your school globally.') }}</li>
                    <li>{{ $t('FREE continuous educational support from WYA accredited schools on workshops, events, seminars, and conferences.') }}</li>
                    <li>{{ $t('Up to 40% discount for joining WYA member park where you can promote your yoga products.') }}</li>
                    <li>{{ $t('More chances to promote and share your yoga activities/articles via WYA Social media.') }}</li>
                  </ul>
                  <h5>{{ $t('Extra Benefits') }}</h5>
                  <ul>
                    <li>{{ $t('Public profile page listed on the WYA website, where you can advertise your classes, and courses, and promote your workshops and yoga events.') }}</li>
                    <li>{{ $t('Academic support in order to help you establish your own business.') }}</li>
                    <li>{{ $t('Ability to use WYA educational standards, syllabuses, and examination system.') }}</li>
                    <li>{{ $t('An ability to provide continuous education (EYEP) for your students or WYA certified yoga teachers worldwide.') }}</li>
                    <li>{{ $t('An opportunity to work with WYA Community as our team member.') }}</li>
                    <p class="font-italic mt-3">{{ $t('Learn more about difference between RSY and CYS , and how to become CYS') }}… <a href="" target="_blank">[ CLICK HERE ]</a></p>
                  </ul>
                </div>
              </div>
            </b-tab>
            <b-tab title="TEACHER BENEFITS">
               <b-table-simple caption-top responsive bordered class="table-default">
                <caption>{{ $t('For Teachers') }}</caption>
                <b-thead>
                  <b-tr>
                    <b-th class="text-center" >{{ $t('Member Benefits') }}</b-th>
                    <b-th class="text-center" >{{ $t('Lifetime member (Premium)')}} </b-th>
                    <b-th class="text-center" >{{ $t('Annual member (Regular)')}} </b-th>
                    <b-th class="text-center" >{{ $t('CYT member')}}<small class="mt-3 pl-1" v-b-popover.hover.bottom="popoverCYT" title="What is CYT member?"><font-awesome-icon :icon="['fas', 'info-circle']"/></small></b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr>
                    <b-td>{{ $t('Worldwide Accreditation') }}</b-td>
                    <b-td class="text-center">✔️</b-td>
                    <b-td class="text-center">✔️</b-td>
                    <b-td class="text-center">✔️</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('Get listed on WYA International Yoga Teacher Directory') }}</b-td>
                    <b-td class="text-center">✔️</b-td>
                    <b-td class="text-center">✔️</b-td>
                    <b-td class="text-center">✔️</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('Permission to use WYA official logos/trademarks according to your registration') }}</b-td>
                    <b-td class="text-center">✔️</b-td>
                    <b-td class="text-center">✔️</b-td>
                    <b-td class="text-center">✔️</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('Promote your classes/events via website & social media') }}</b-td>
                    <b-td class="text-center">✔️</b-td>
                    <b-td class="text-center">✔️</b-td>
                    <b-td class="text-center">✔️</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('Public teacher profile') }}</b-td>
                    <b-td class="text-center">✔️</b-td>
                    <b-td class="text-center">✔️</b-td>
                    <b-td class="text-center">✔️</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('Access to WYA online community') }}</b-td>
                    <b-td class="text-center">✔️</b-td>
                    <b-td class="text-center">✔️</b-td>
                    <b-td class="text-center">✔️</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('Access to WYA exclusive educational center') }}</b-td>
                    <b-td class="text-center">✔️</b-td>
                    <b-td class="text-center">✔️</b-td>
                    <b-td class="text-center">✔️</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('Organization support for establishing your own yoga studio') }}</b-td>
                    <b-td class="text-center">✔️</b-td>
                    <b-td class="text-center">❌</b-td>
                    <b-td class="text-center">✔️</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('Official support to become Yoga Training Courses Provider') }}</b-td>
                    <b-td class="text-center">✔️</b-td>
                    <b-td class="text-center">❌</b-td>
                    <b-td class="text-center">✔️</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('Join WYA continuous education program (EYEP) - ongoing education') }}</b-td>
                    <b-td class="text-center">❌</b-td>
                    <b-td class="text-center">❌</b-td>
                    <b-td class="text-center">✔️</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('FREE participation in our Annual Global Conferences and WYA Family Event both Online and Onsite') }}</b-td>
                    <b-td class="text-center">❌</b-td>
                    <b-td class="text-center">❌</b-td>
                    <b-td class="text-center">✔️</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('An opportunity to work with WYA Community as our team member / volunteer') }}</b-td>
                    <b-td class="text-center">❌</b-td>
                    <b-td class="text-center">❌</b-td>
                    <b-td class="text-center">✔️</b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
              <div class="row pt-3">
                <div class="col-12 col-md-2 mb-4">
                  <span class="d-block text-center">
                    <img :src="PUBLIC_PATH+'img/certificate/RTY.png'" width="90%" height="auto" title="RTY" alt="">
                  </span>
                </div>
                 <div class="col-12 col-md-10">
                  <h5>{{ $t('Benefits for RTY － Registered Teacher of Yoga') }}</h5>
                  <ul>
                    <li>{{ $t('Accreditation from World Yoga Alliance (WYA) enhances your credibility nationally and internationally.') }}</li>
                    <li>{{ $t('Get your teacher profile listed on') }}  <router-link :to="{ name: 'searchDirectory', query: { type: 'teacher' }}">{{ $t('WYA International Directory of Yoga Teacher') }}</router-link> {{ $t('as official member.') }} </li>
                    <li>{{ $t('Permission to use WYA official logos according to your registration.') }}</li>
                    <li>{{ $t('A chance to get yourself featured and promote via WYA social media.') }}</li>
                    <li>{{ $t('15% discount for all RTY members on events or other courses organized by our community. (WYA Teacher ID is required)') }}</li>
                    <li>{{ $t('15% discount to join WYA member park where you can promote your yoga products including yoga accessaries, apparel, props, books, etc.') }}</li>
                    <li>{{ $t('Purchase yoga products with special offers from WYA member park store partners.') }}</li>
                  </ul>
                </div>
              </div>
              <hr class="col-12 mt-5 mb-5" />
              <div class="row">
                <div class="col-12 col-md-2 mb-4">
                  <span class="d-block text-center">
                    <img :src="PUBLIC_PATH+'img/certificate/CYT.png'" width="100%" height="auto" title="CYT" alt="">
                  </span>
                  <span class="d-block mt-4 text-center">
                    <img :src="PUBLIC_PATH+'img/certificate/CYT_new_sample.jpeg'" width="100%" height="auto" title="CYT" alt="" style="max-height=130px;">
                  </span>
                  <span class="d-block mt-4 text-center">
                    <img :src="PUBLIC_PATH+'img/certificate/CYT_mini_sample.jpeg'" width="100%" height="auto" title="CYT" alt="" style="max-height=130px;">
                  </span>
                </div>
                 <div class="col-12 col-md-10">
                  <h5>{{ $t('Benefits for CYT － Certified Yoga Teacher') }}</h5>
                  <ul>
                    <li>{{ $t('Accreditation from World Yoga Alliance (WYA) enhances your credibility nationally and internationally.') }}</li>
                    <li>{{ $t('Get your teacher profile listed on WYA International Directory of Yoga Teacher as official member.') }}</li>
                    <li>{{ $t('Permission to use WYA official logos according to your registration.') }}</li>
                    <li>{{ $t('More chances to get yourself featured and promote via WYA social media.') }}</li>
                    <li><b>{{ $t('FREE') }}</b> {{ $t('participation for WYA events or other courses organized by our community. (WYA Teacher ID is required)') }}</li>
                    <li>{{ $t('15% discount to join WYA member park where you can promote your yoga products including yoga accessaries, apparel, props, books, etc.') }}</li>
                    <li>{{ $t('Purchase yoga products with special offers from WYA member park store partners.') }}</li>
                  </ul>
                  <h5>{{ $t('Benefits for CYT － Certified Yoga Teacher') }}</h5>
                  <ul>
                    <li>{{ $t('An ability to join FREE continuous yoga educational program (EYEP) for the same training level within 2-3 years after completion of the training with WYA Certified Yoga School/Divisions worldwide. (WYA Teacher ID is required)') }}</li>
                    <li><b>{{ $t('FREE') }}</b> {{ $t('Internship Course for 2 years after completion of the training with Division of World Yoga Alliance. Our internship programs can help you gain more practical experience as a yoga teacher, giving you an extra letter of recommendation upon successful completion.') }}</li>
                    <li><b>{{ $t('FREE') }}</b> {{ $t('lifetime membership to be assisted and get organizational support for establishing your own yoga studio or unite with WYA (Certified members must register themselves as an international yoga teacher—RTY first.)') }}</li>
                    <li>{{ $t('Get 25% discount for Registered Teacher of Yoga (RTY) registration fee.') }}</li>
                    <li><b>{{ $t('FREE') }}</b> {{ $t('ongoing educational support in the form of conferences, seminars and workshops.') }}</li>
                    <li>{{ $t('An opportunity to work with WYA Community as our team member, brand ambassador, assistant teachers and more.') }}</li>
                    <p class="font-italic mt-3"><b>{{ $t('Remark') }}:</b> {{ $t('To become WYA Certified Yoga Teacher, you have to complete the training with WYA Certified Yoga School (CYS) where they provide WYA certificate upon completion of the training with valid WYA teacher ID.') }}</p>
                    <p class="font-italic">{{ $t('Therefore, Certified Yoga Teacher (CYT) can be applied by WYA Certified Yoga School (CYS) only.') }}</p>
                    <p class="font-italic">{{ $t('To identify Certified Yoga School, please refer to WYA School Directory.') }} <router-link :to="{ name: 'searchDirectory'}">[ {{ $t('CLICK HERE') }} ] </router-link>{{ $t('There will be “CYS logo” on the school that provide WYA certificate for the training. We recommend you  confirm with the school directly before participating in any trainings.') }}</p>
                  </ul>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'MemberBenefit',
  title: ' | Member Benefits',
  data () {
    return {
      loading: true,
      tabIndex: 0
    }
  },
  beforeCreate () {
    this.loading = true
  },
  watch: {
    $route (to, _) {
      this.scrollToElement(to.hash)
    },
    tabIndex: function () {
      if (this.tabIndex === 0) {
        window.location.hash = '#schools'
      } else if (this.tabIndex === 1) {
        window.location.hash = '#teachers'
      }
      console.log('tabIndex', this.tabIndex)
    }
  },
  async mounted () {
    window.scrollTo(0, 0)
    await this.toggleBurgerButtonClass('addClass', 'bars-bg-gray')
    await this.toggleNavbarNavClass('addClass', 'nav-clean')
    this.loading = false

    setTimeout(() => {
      this.scrollToElement(this.$route.hash)
    }, 1000)
    const EventBus = this.$root
    EventBus.$on('on-change-hash', (hash) => {
      this.scrollToElement(hash)
    })
  },
  computed: {
    popoverCYT () {
      // Both title and content specified as a function in this example
      // and will be called the each time the popover is opened
      return {
        html: true,
        title: () => {
          // Note this is called only when the popover is opened
          return 'What is CYT member?'
        },
        content: () => {
          // Note this is called only when the popover is opened
          return "<p><strong>CYT - Certified Yoga Teacher</strong></p><p>CYT is limited to yoga teacher that graduated from WYA Certified Yoga School (CYS).</p><p>They'll get CYT Teacher ID with beneficial of continuous education from organization.</p>"
        }
      }
    }
  },
  methods: {
    scrollToElement (hash) {
      const headerHeight = 105
      const bannerHeight = document.getElementById('banner').offsetHeight
      const contentHeight = document.getElementById('content').offsetHeight
      const topOfElement = headerHeight + bannerHeight + contentHeight - headerHeight
      if (hash === '#schools') {
        this.tabIndex = 0
        window.scroll({ top: topOfElement, behavior: 'smooth', block: 'start' })
      } else if (hash === '#teachers') {
        this.tabIndex = 1
        window.scroll({ top: topOfElement, behavior: 'smooth', block: 'start' })
      } else if (hash === '#CYS') {
        this.tabIndex = 0
        const element = document.getElementById('CYS')
        const elementOffset = element.offsetTop + bannerHeight - headerHeight
        window.scroll({ top: elementOffset, behavior: 'smooth', block: 'start' })
      }
    },
    toggleBurgerButtonClass (addRemoveClass, className) {
      const burger = document.querySelector('.bm-burger-button')
      if (addRemoveClass === 'addClass') {
        burger.classList.add(className)
      } else {
        burger.classList.remove(className)
      }
    },
    toggleNavbarNavClass (addRemoveClass, className) {
      const nav = document.querySelector('.navbar-nav')
      if (addRemoveClass === 'addClass') {
        nav.classList.add(className)
      } else {
        nav.classList.remove(className)
      }
    }
  },
  destroyed () {
    this.toggleBurgerButtonClass('removeClass', 'bars-bg-gray')
    this.toggleNavbarNavClass('removeClass', 'nav-clean')
  }
}
</script>
<style scoped>
 #member-benefits {
  /* Lock Text */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
</style>
